<template>
  <div>
    <CustomerFilter
      :filter="filter"
      :all-ref="allRef"
      :customer-type-filter.sync="filter.searchCustomerType"
      :customer-group-filter.sync="filter.searchCustomerGroup"
      :search-customer-id.sync="filter.searchCustomerId"
      :search-customer-phone.sync="filter.searchPhone"
      :search-customer-name.sync="filter.searchCustomerName"
      @fetch-filter="fetchFilter()" />
    <b-card class="mt-2">
      <TableTop
        v-model="pagination.limit"
        label="Customer Profile Management"
        to-path="/customer/add"
        @input="fetchFilter()" />
      <b-table
        :items="items"
        :fields="fields"
        striped
        hover
        responsive
        @row-clicked="rowClick($event)">
        <template #cell(action)="{item}">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="w-100"
            variant="primary"
            @click="createTicket(item.id)"
          >
            <span class="align-middle">Open Case</span>
          </b-button>
        </template>
      </b-table>
    </b-card>
    <b-col
      cols="12"
      sm="12"
      class="d-flex align-items-center justify-content-center justify-content-sm-end">
      <b-pagination
        v-model="pagination.page"
        :total-rows="pagination.totalDocs"
        :per-page="pagination.limit"
        first-number
        last-number
        class="mb-0 mt-1 mt-sm-0"
        prev-class="prev-item"
        next-class="next-item"
        @change="showCurrentPage($event)">
        <template #prev-text>
          <feather-icon
            icon="ChevronLeftIcon"
            size="18" />
        </template>
        <template #next-text>
          <feather-icon
            icon="ChevronRightIcon"
            size="18" />
        </template>
      </b-pagination>
    </b-col>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CustomerFilter from '@/views/Customers/Components/CustomerFilter.vue'
import TableTop from '@/components/TableTop.vue'
import CustomerProvider from '@/resources/CustomerProvider'
import CustomerGroupProvider from '@/resources/CustomerGroupProvider'
import CustomerTypeProvider from '@/resources/CustomerTypeProvider'
import CompanyGroupProvider from '@/resources/CompanyGroupProvider'
import CompanyDepartmentProvider from '@/resources/CompanyDepartmentProvider'
import CompanyCenterProvider from '@/resources/CompanyCenterProvider'
import TicketProvider from '@/resources/TicketProvider'

const CustomerService = new CustomerProvider()
const CustomerGroupService = new CustomerGroupProvider()
const CustomerTypeService = new CustomerTypeProvider()
const CompanyGroupService = new CompanyGroupProvider()
const CompanyDepartmentService = new CompanyDepartmentProvider()
const CompanyCenterService = new CompanyCenterProvider()
const TicketService = new TicketProvider()

export default {
  components: {
    CustomerFilter,
    TableTop
  },
  data () {
    return {
      isDeleteModalActive: false,
      perPage: 10,
      currentPage: 1,
      totalUsers: 100,
      filter: {
        searchPhone: '',
        searchCustomerType: '',
        searchCustomerGroup: '',
        searchCustomerId: '',
        searchCustomerName: ''
      },
      pagination: {
        page: 1,
        limit: 10,
        totalDocs: 10
      },
      fields: [
        { key: 'customerId', label: 'Customer Id' },
        { key: 'name', label: 'Customer Name' },
        { key: 'customerType.name', label: 'Customer Type' },
        { key: 'customerGroup.name', label: 'Customer Group' },
        { key: 'ani', label: 'Ani' },
        { key: 'sapSoldToCode', label: 'SAP Sold To Code' },
        { key: 'sapShipToCode', label: 'SAP Ship Code' },
        { key: 'companyOfficePhone', label: 'Company Office Phone' },
        { key: 'companyMobilePhone', label: 'Company Moblie Phone' },
        { key: 'action', label: '' }
      ],
      items: [],
      initialCustomer: {},
      selectedItem: {},
      deleteItem: {},
      allRef: {
        customerGroup: [],
        customerType: [],
        companyGroup: [],
        companyDepartment: [],
        companyCenter: []
      }
    }
  },
  async created () {
    // await this.getCustomerProfile()
    await this.getAllRef()
  },
  computed: {
    ...mapGetters({
      // map `this.doneCount` to `this.$store.getters.doneTodosCount`
      profile: 'User/user'
    })
  },
  methods: {
    async getCustomerProfile () {
      const data = await CustomerService.paginate(this.pagination.page, this.pagination.limit, this.filter)
      this.setPagination(data.page, data.totalDocs)
      this.items = [...data.data]
    },
    async getAllRef () {
      try {
        const customerGroup = await CustomerGroupService.findAllGroup()
        const customerType = await CustomerTypeService.findAllType()
        const companyGroup = await CompanyGroupService.findAllGroup()
        const companyDepartment = await CompanyDepartmentService.findAllCompanyDepartment()
        const companyCenter = await CompanyCenterService.findAllCompanyCenter()
        this.allRef.customerType = customerType.data
        this.allRef.customerGroup = customerGroup.data
        this.allRef.companyGroup = companyGroup.data
        this.allRef.companyDepartment = companyDepartment.data
        this.allRef.companyCenter = companyCenter.data
      } catch (error) {
        console.error(error)
      }
    },
    async createTicket (userId) {
      this.$store.dispatch('activity/increaseLoading')
      try {
        const { data } = await TicketService.createTicketByCustomer(userId, this.profile.id)
        if (data) {
          this.$router.push(`/case/detail/${data?.id}`)
        }
      } catch (error) {
        console.error(error)
      } finally {
        this.$store.dispatch('activity/decreaseLoading')
      }
    },
    rowClick (val) {
      this.selectedItem = { ...val }
      this.initialCustomer = { ...val }
      this.$router.push(`/customer/detail/${val.id}`)
    },
    fetchFilter () {
      this.setPagination(1)
      this.getCustomerProfile()
    },
    showCurrentPage (page) {
      this.setPagination(page)
      this.getCustomerProfile()
    },
    setPagination (page, totalDocs) {
      this.pagination.page = page || this.pagination.page
      this.pagination.totalDocs = totalDocs || this.pagination.totalDocs
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0;
}
</style>
