<template>
  <div>
    <b-row class="align-items-end">
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <FilterDropdown
          v-model="searchCustomerType"
          label="Customer Type"
          placeholder="Customer Type"
          :options="allRef.customerType"
          label-option="name"
          return-value="id"
          @input="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <FilterDropdown
          v-model="searchCustomerGroup"
          label="Customer Group"
          placeholder="Customer Group"
          :options="allRef.customerGroup"
          label-option="name"
          return-value="id"
          @input="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="4">
      </b-col>
    </b-row>
    <b-row class="align-items-end mt-1">
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <SearchTextInput
          v-model="searchCustomerId"
          label="Search Customer Id"
          placeholder="Search Customer Id"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <SearchTextInput
          v-model="searchCustomerPhone"
          label="Search Phone Number"
          placeholder="Search Phone Number"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        class="mb-1 mb-sm-0"
        sm="12"
        md="3">
        <SearchTextInput
          v-model="searchCustomerName"
          label="Search Customer Name"
          placeholder="Search Customer Name"
          @keyup.enter.native="fetchFilter()" />
      </b-col>
      <b-col
        sm="12"
        md="2"
        class="mb-1 mb-sm-0">
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          class="w-100"
          variant="primary"
          @click="fetchFilter()"
        >
          <feather-icon
            icon="SearchIcon"
            class="mr-50 mr-md-0 mr-lg-0 mr-xl-50"
          />
          <span class="align-middle">Search</span>
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SearchTextInput from '@/components/Filter/SearchTextInput.vue'
import FilterDropdown from '@/components/Filter/FilterDropdown.vue'

export default {
  components: {
    SearchTextInput,
    FilterDropdown
  },
  props: {
    allRef: {
      type: Object,
      default: () => ({
        customerGroup: [],
        customerType: [],
        companyGroup: [],
        companyDepartment: [],
        companyCenter: []
      })
    },
    filter: {
      type: Object,
      default: () => ({
        searchPhone: '',
        searchCustomerType: '',
        searchCustomerGroup: '',
        searchCustomerId: '',
        searchCustomerName: ''
      })
    }
  },
  data () {
    return {
      optionsType: [
        { label: 'VIP', value: '00' },
        { label: 'ลูกค้าทั่วไป', value: '01' }
      ],
      optionsGroup: [
        { label: 'Priority 1', value: '01' },
        { label: 'Priority 2', value: '02' },
        { label: 'Priority 3', value: '03' },
        { label: 'Priority 4', value: '04' },
        { label: 'Priority 5', value: '05' }
      ]
    }
  },
  computed: {
    searchCustomerType: {
      get () {
        return this.filter.searchCustomerType
      },
      set (val) {
        this.$emit('update:customer-type-filter', val)
      }
    },
    searchCustomerGroup: {
      get () {
        return this.filter.searchCustomerGroup
      },
      set (val) {
        this.$emit('update:customer-group-filter', val)
      }
    },
    searchCustomerId: {
      get () {
        return this.filter.searchCustomerId
      },
      set (val) {
        this.$emit('update:search-customer-id', val)
      }
    },
    searchCustomerPhone: {
      get () {
        return this.filter.searchPhone
      },
      set (val) {
        this.$emit('update:search-customer-phone', val)
      }
    },
    searchCustomerName: {
      get () {
        return this.filter.searchCustomerName
      },
      set (val) {
        this.$emit('update:search-customer-name', val)
      }
    }
  },
  methods: {
    fetchFilter () {
      this.$emit('fetch-filter')
    }
  }
}
</script>

<style>

</style>
